var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{attrs:{"id":"editTemplateModelForm"},on:{"submit":function($event){$event.preventDefault();return _vm.updateTemplateModel.apply(null, arguments)}}},[_c('b-card',[_c('b-container',[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 159, 67, 0.15)'),expression:"'rgba(255, 159, 67, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"outline-warning","to":"/configuration/template-models","title":"Go Back"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"20"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"10"}},[_c('h1',[_vm._v("Edit Campaign Model")])])],1),_c('hr'),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Campaign Model Title: *","label-for":"h-title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required|regex:^[a-zA-Z].*$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-title","state":errors.length > 0 ? false : null,"placeholder":"Title","autofocus":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Campaign Model Image : *","label-for":"tempImage"}},[_c('b-img',{attrs:{"thumbnail":"","fluid":"","src":_vm.path + this.imagePreview}}),_c('validation-provider',{attrs:{"name":"Image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"mt-1",attrs:{"id":"image","accept":"image/*","state":errors.length > 0 ? false : null,"placeholder":"Click Here to Upload an Image"},on:{"change":_vm.ImageInputChanged}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Template:"}},[_c('editor',{attrs:{"api-key":"puknvskl3ay0k40d6ltd1mq8fueses5pvh2xoktlmtssny8q","init":{
                selector: '.editor',
                plugins:
                  'fullpage code advlist link image preview lists wordcount',
                bbcode_dialect: 'punbb',
                height: 600,
              }},model:{value:(_vm.templateContent),callback:function ($$v) {_vm.templateContent=$$v},expression:"templateContent"}})],1)],1)],1),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 159, 67, 0.15)'),expression:"'rgba(255, 159, 67, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"outline-success"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }